import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ApiAuthentificationMethod from "../BotApiAuthentificationMethod"

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "40%",
    left: "40%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  selectField: {
    height: 40,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "35%"
  }
}));

const StepApiType = ({ initialData, onChange }) => {
  const classes = useStyles();
  const [showAuthentificationMethod, setShowAuthentificationMethod] = useState(false);

  const [formData, setFormData] = useState({
    endpoint: "",
    method: "get",
    param: "",
    param_type: "query",
    response_field: "",
    auth_method: "no_auth",
    auth: {
      token: "",
      apikey: "",
      user: "",
      password: "",
    }
  });

  useEffect(() => {
    setFormData({
      endpoint: initialData?.endpoint || "",
      method: initialData?.method || "get",
      param: initialData?.param || "",
      param_type: initialData?.param_type || "query",
      response_field: initialData?.response_field || "",
      auth_method: initialData?.auth_method || "no_auth",
      auth: initialData?.auth || {}
    });
    setShowAuthentificationMethod(initialData?.auth_method !== "no_auth");
  }, [initialData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    const data = { ...formData, [name]: value };
    const dataToParent = {
      target: {
        name: "api",
        value: data,
      }
    };
    onChange(dataToParent);
  };

  const handleChangeAuthenticationMethod = (event) => {
    handleInputChange(event);

    if (formData.auth_method === "no_auth" && event.target.value !== "no_auth") {
      setShowAuthentificationMethod(true);
      return;
    }

    if (event.target.value === "no_auth" && formData.auth_method !== "no_auth") {
      setShowAuthentificationMethod(false);
      return;
    }
  }

  return (
    <div style={{ marginLeft: 20 }} >
      <Select
        id="select_request_method"
        labelId="label_select_request_method"
        name="method"
        label={i18n.t("bots.botStepApiType.method")}
        value={formData.method}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
        className={classes.selectField}
      >
        <MenuItem value="get" selected={true}>{i18n.t("bots.botStepApiType.get")}</MenuItem>
        <MenuItem value="post">{i18n.t("bots.botStepApiType.post")}</MenuItem>
      </Select>
      <TextField
        label={i18n.t("bots.botStepApiType.endpoint")}
        name="endpoint"
        variant="outlined"
        margin="dense"
        className={classes.textField}
        style={{ width: "60%" }}
        onChange={handleInputChange}
        value={formData.endpoint}
      />
      <Select
        labelId="label_select_param_type"
        id="select_param_type"
        name="param_type"
        label={i18n.t("bots.botStepApiType.param_type")}
        value={formData.param_type}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
        className={classes.selectField}
      >
        <MenuItem value="query" selected={true}>{i18n.t("bots.botStepApiType.query")}</MenuItem>
        <MenuItem value="url">{i18n.t("bots.botStepApiType.url")}</MenuItem>
        <MenuItem value="body">{i18n.t("bots.botStepApiType.body")}</MenuItem>
      </Select>
      <TextField
        label={i18n.t("bots.botStepApiType.param")}
        name="param"
        variant="outlined"
        margin="dense"
        className={classes.textField}
        style={{ width: "60%" }}
        onChange={handleInputChange}
        value={formData.param}
      />

      <Select
        labelId="label_select_request_method"
        id="select_request_method"
        name="auth_method"
        label={i18n.t("bots.botStepApiType.auth_method")}
        value={formData.auth_method}
        onChange={handleChangeAuthenticationMethod}
        fullWidth
        className={classes.selectField}
        variant="outlined"
      >
        <MenuItem value="no_auth" selected={true}>{i18n.t("bots.botStepApiType.no_auth")}</MenuItem>
        <MenuItem value="basic">{i18n.t("bots.botStepApiType.basic")}</MenuItem>
        <MenuItem value="token">{i18n.t("bots.botStepApiType.token")}</MenuItem>
        <MenuItem value="apikey">{i18n.t("bots.botStepApiType.apikey")}</MenuItem>
      </Select>
      <TextField
        label={i18n.t("bots.botStepApiType.response_field")}
        name="response_field"
        variant="outlined"
        margin="dense"
        className={classes.textField}
        style={{ width: "60%" }}
        onChange={handleInputChange}
        value={formData.response_field}
      />
      {showAuthentificationMethod ? <ApiAuthentificationMethod method={formData?.auth_method} initialData={formData?.auth} onChange={handleInputChange} /> : <></>}
    </div>);
}

export default StepApiType;