import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
// import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DeleteOutline } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import BotOptionModal from "../BotOptionModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "40%",
		left: "40%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	selectField: {
		height: 40,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "35%"
	}
}));

const BotAddMenuModal = ({ open, onClose, onSubmit, onRemove, initialData }) => {
  const classes = useStyles();

  const [showModalOption, setShowModalOption] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [formData, setFormData] = useState({
    "menu_name": "",
    "label": "",
    "attemps": "",
    "validator": "",
    "reserved_word": "",
    "options": [],
  });

  const [selectedOptionToEdit, setSelectedOptionToEdit] = useState({});

  const onModalOptionSubmit = (data) => {
    data["goto"] = {
      "target": data.goto_target,
      "code": data.goto_code,
    }

    delete data["goto_target"]
    delete data["goto_code"]

    const options = formData.options;
    const selectedIndex = options.findIndex((e) => e.code === data.code);

    if (selectedIndex === -1) {
      options.push(data);
    } else {
      options[selectedIndex] = data;
    }

    setFormData({ ...formData, options });
  }

  const handleSubmitForm = () => {
    let data = formData;
    data["attemps"] = data["attemps"].split(",");
    onSubmit(data);
  }

  useEffect(() => {
    setFormData({
      "menu_name": initialData?.menu_name || "",
      "label": initialData?.label || "",
      "validator": initialData?.validator || "",
      "attemps": (initialData?.attemps || []).join(","),
      "options": initialData?.options || [],
      "reserved_word": initialData?.reserved_word || "",
    })
  }, [initialData])

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {i18n.t("bots.botAddMenuModal.title")}
          {initialData ?
            <Button onClick={() => { onRemove(initialData.menu_name) }}>
              <DeleteOutline />
            </Button> :
            <></>}
        </DialogTitle>
        <Formik
          enableReinitialize={true}
          initialValues={formData}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              // handleSaveQueue(values);
              handleSubmitForm();
              onClose();
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <TextField
                  label={i18n.t("bots.botAddMenuModal.fields.menu_name")}
                  autoFocus
                  name="menu_name"
                  error={touched.menu_name && Boolean(errors.menu_name)}
                  helperText={touched.menu_name && errors.menu_name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  style={{ width: "45%" }}
                  onChange={handleInputChange}
                  value={formData.menu_name}
                />

                <TextField
                  label={i18n.t("bots.botAddMenuModal.fields.text")}
                  autoFocus
                  name="label"
                  error={touched.label && Boolean(errors.label)}
                  helperText={touched.label && errors.label}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  style={{ width: "45%" }}
                  onChange={handleInputChange}
                  value={formData.label}
                />
                <TextField
                  label={i18n.t("bots.botAddMenuModal.fields.validator")}
                  name="validator"
                  error={touched.validator && Boolean(errors.validator)}
                  helperText={touched.validator && errors.validator}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  onChange={handleInputChange}
                  value={formData.validator}
                  style={{ width: "45%" }}
                />
                <TextField
                  label={i18n.t("bots.botAddMenuModal.fields.reserved_word")}
                  name="reserved_word"
                  error={touched.reserved_word && Boolean(errors.reserved_word)}
                  helperText={touched.reserved_word && errors.reserved_word}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  onChange={handleInputChange}
                  value={formData.reserved_word}
                  style={{ width: "40%" }}
                />
                <TextField
                  label={i18n.t("bots.botAddMenuModal.fields.attemps")}
                  autoFocus
                  name="attemps"
                  error={touched.attemps && Boolean(errors.attemps)}
                  helperText={touched.attemps && errors.attemps}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  multiline
                  minRows={3}
                  style={{ width: "91%" }}
                  onChange={handleInputChange}
                  value={formData.attemps}
                />
                <hr />
                <h2> {i18n.t("bots.botAddMenuModal.options")} </h2>
                {
                  formData.options.map((e) => {
                    return <Button
                      key={e.code}
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        setSelectedOptionToEdit(e);
                        setShowModalOption(true);
                      }}
                    >
                      {e.label}
                    </Button>;
                  })
                }
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                  onClick={() => {
                    setSelectedOptionToEdit(null);
                    setShowModalOption(true);
                  }}

                >
                  {i18n.t("bots.botAddMenuModal.add_option")}
                </Button>
                <Button
                  onClick={onClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("bots.botAddMenuModal.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {i18n.t("bots.botAddMenuModal.add")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>

      </Dialog>
      <BotOptionModal open={showModalOption} onClose={() => { setShowModalOption(false); }} onSubmit={(data) => { onModalOptionSubmit(data) }} initialData={selectedOptionToEdit} />
    </div >
  );

};

export default BotAddMenuModal