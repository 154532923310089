import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueueSelect from "../QueueSelect";

import { DeleteOutline } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "40%",
		left: "40%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	selectField: {
		height: 40,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "35%"
	}
}));

const BotOptionModal = ({ open, onClose, onSubmit, initialData }) => {
	const classes = useStyles();

	// const [isSubmitting, setIsSubmitting] = useState(false);
	const [formData, setFormData] = useState({
		"goto_target": "",
		"goto_code": "",
		"label": "",
		"code": "",
		"static_response": "",
		"assign_to": ""
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	useEffect(() => {
		setFormData({
			"goto_target": initialData?.goto?.target || "menu",
			"goto_code": initialData?.goto?.code || "",
			"label": initialData?.label || "",
			"code": initialData?.code || "",
			"static_response": initialData?.static_response || "",
			"assign_to": initialData?.assign_to || ""
		});
	}, [initialData]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={onClose}
			>
				<DialogTitle>
				{i18n.t("bots.botOptionModal.title")}
					{initialData ?
						<Button>
							<DeleteOutline />
						</Button> :
						<></>}

				</DialogTitle>

				<Formik
					initialValues={formData}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							actions.setSubmitting(false);
							onClose();
							onSubmit(formData);
							setFormData({})
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => {
						return (
							<Form>
								<DialogContent dividers>
									<QueueSelect
										selectedQueueIds={[formData.assign_to]}
										onChange={values => setFormData({ ...formData, "assign_to": values, "goto_target": "finish", "goto_code": "" })}
										multiple={false}
									/>
									<TextField
										disabled={initialData?.code?.length > 0}
										label={i18n.t("bots.botOptionModal.fields.code")}
										autoFocus
										name="code"
										error={touched.code && Boolean(errors.code)}
										helperText={touched.code && errors.code}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										onChange={handleInputChange}
										fullWidth
										value={formData.code}
									/>
									<TextField
										label={i18n.t("bots.botOptionModal.fields.label")}
										name="label"
										error={touched.label && Boolean(errors.label)}
										helperText={touched.label && errors.label}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										onChange={handleInputChange}
										value={formData.label}
										fullWidth
									/>
									<TextField
										label={i18n.t("bots.botOptionModal.fields.static_response")}
										name="static_response"
										error={touched.static_response && Boolean(errors.static_response)}
										helperText={touched.static_response && errors.static_response}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										onChange={handleInputChange}
										value={formData.static_response}
										fullWidth
                    multiline
									/>
									<hr />
									<h2>{i18n.t("bots.botOptionModal.goto")}</h2>
									<Select
										labelId="select-label"
										id="simple-select"
										name="goto_target"
										label={i18n.t("bots.botOptionModal.type")}
										value={formData.goto_target}
										onChange={handleInputChange}
										fullWidth
										className={classes.selectField}
										variant="outlined"
									>
										<MenuItem value="menu" selected={true}>{i18n.t("bots.botOptionModal.select_options.menu")}</MenuItem>
										<MenuItem value="steps">{i18n.t("bots.botOptionModal.select_options.step")}</MenuItem>
										<MenuItem value="finish">{i18n.t("bots.botOptionModal.select_options.finish")}</MenuItem>
									</Select>
									<TextField
										label={i18n.t("bots.botOptionModal.goto_code")}
										name="goto_code"
										error={touched.goto_code && Boolean(errors.goto_code)}
										helperText={touched.goto_code && errors.goto_code}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										fullWidth
										onChange={handleInputChange}
										value={formData.goto_code}
									/>
								</DialogContent>
								<DialogActions>
									<Button
										color="primary"
										variant="contained"
										className={classes.btnWrapper}
										onClick={onClose}
									>
										{i18n.t("bots.botOptionModal.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{i18n.t("bots.botOptionModal.add")}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</DialogActions>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</div >
	);
};

export default BotOptionModal;