import React, { useState, useEffect } from "react";

import { Formik, Form, Field } from "formik";
// import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";

import { i18n } from "../../translate/i18n";
// import api from "../../services/api";
// import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "40%",
		left: "40%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	selectField: {
		height: 40,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "35%"
	}
}));

const BotModal = ({ open, onClose, botId }) => {
	const classes = useStyles();

	const [botData, setBotData] = useState({
		"id": "",
		"bot": "",
		"status": 1,
	})

	useEffect(() => {
		(async () => {
			try {
				if (!botId) {
					return;
				}
				const { data } = await api.get(`/bots/${botId}`);
				setBotData(data);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [botId]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setBotData((prevData) => ({ ...prevData, [name]: value }));
	};


	const handleClose = () => {
		setBotData({});
		onClose();
	};

	const handleSubmitForm = async () => {
		if (botId) {
			await api.put("/bots", botData);
			return;
		}

		await api.post("/bots", botData);
	}

	return (
		<div>
			<Dialog open={open} onClose={handleClose} scroll="paper" fullWidth maxWidth="sm">
				<DialogTitle>
					{botId ? i18n.t("bots.botInfoModal.edit")
						: i18n.t("bots.botInfoModal.add")}
				</DialogTitle>

				<Formik
					enableReinitialize={true}
					initialValues={botData}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							// handleSaveQueue(values);
							actions.setSubmitting(false);
							handleSubmitForm();
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("bots.botInfoModal.bot_name")}
									autoFocus
									name="bot"
									error={touched.bot && Boolean(errors.bot)}
									helperText={touched.bot && errors.bot}
									variant="outlined"
									margin="dense"
									className={classes.textField}
									onChange={handleInputChange}
									value={botData?.bot || ""}
									style={{ width: "95%" }}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
									onClick={handleClose}
								>
									{i18n.t("bots.botInfoModal.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{botId
										? i18n.t("bots.botInfoModal.edit")
										: i18n.t("bots.botInfoModal.add")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default BotModal;
