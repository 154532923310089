import React, { useState } from "react";
import i18n from "i18next";

const LanguageSwitcher = () => {
  const [language, setLanguage] = useState(i18n.language);

  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
    window.location.reload();
  };

  return (
    <select value={language} onChange={handleChangeLanguage} style={{
      borderRadius: "10px",
      width: "96px",
      height: "23px",
      textAlign: "center",
    }}>
      <option value="en">English</option>
      <option value="es">Espa&ntilde;ol</option>
      <option value="pt">Portugues</option>
    </select>
  );
};

export default LanguageSwitcher;
