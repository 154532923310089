import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Paper, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import ContactDrawer from "../ContactDrawer";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";

function ViewTicketModal({ isOpen, close, ticketId }) {

      const drawerWidth = 320;

      const useStyles = makeStyles((theme) => ({
            root: {
                  display: "flex",
                  height: "500px",
                  position: "relative",
                  'overflow-y': "hidden",
                  'overflow-x': "hidden",
            },

            ticketInfo: {
                  maxWidth: "50%",
                  flexBasis: "50%",
                  [theme.breakpoints.down("sm")]: {
                        maxWidth: "80%",
                        flexBasis: "80%",
                  },
            },
            ticketActionButtons: {
                  maxWidth: "50%",
                  flexBasis: "50%",
                  display: "flex",
                  [theme.breakpoints.down("sm")]: {
                        maxWidth: "100%",
                        flexBasis: "100%",
                        marginBottom: "5px",
                  },
            },

            mainWrapper: {
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeft: "0",
                  marginRight: -drawerWidth,
                  transition: theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                  }),
            },

            mainWrapperShift: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  transition: theme.transitions.create("margin", {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                  }),
                  marginRight: 0,
            },
      }));

      const classes = useStyles();
      const history = useHistory();
      const [drawerOpen, setDrawerOpen] = useState(false);
      const [loading, setLoading] = useState(true);
      const [contact, setContact] = useState({});
      const [ticket, setTicket] = useState({});

      useEffect(() => {
            setLoading(true);
            const delayDebounceFn = setTimeout(() => {
                  const fetchTicket = async () => {
                        try {
                              const { data } = await api.get("/tickets/" + ticketId);
                              setContact(data.contact);
                              setTicket(data);
                              setLoading(false);
                        } catch (err) {
                              setLoading(false);
                              toastError(err);
                        }
                  };
                  fetchTicket();
            }, 500);
            return () => clearTimeout(delayDebounceFn);
      }, [ticketId, history]);


      const handleDrawerOpen = () => {
            setDrawerOpen(true);
      };

      const handleDrawerClose = () => {
            setDrawerOpen(false);
      };




      return (
            <Modal
                  open={isOpen}
                  onClose={close}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                  }}
                  onClick={(e) => { e.stopPropagation(); }}
            >
                  <Box sx={{ position: 'absolute', width: 800, bgcolor: 'background.paper' }}>

                        <div className={classes.root} id="drawer-container">
                              <Paper
                                    variant="outlined"
                                    elevation={0}
                                    className={clsx(classes.mainWrapper, {
                                          [classes.mainWrapperShift]: drawerOpen,
                                    })}
                              >
                                    <TicketHeader loading={loading} backButton={false}>
                                          <div className={classes.ticketInfo}>
                                                <TicketInfo
                                                      contact={contact}
                                                      ticket={ticket}
                                                      onClick={handleDrawerOpen}
                                                />
                                          </div>
                                    </TicketHeader>
                                    <ReplyMessageProvider>
                                          <MessagesList
                                                ticketId={ticketId}
                                                isGroup={ticket.isGroup}
                                                setRead={false}
                                          ></MessagesList>
                                    </ReplyMessageProvider>
                              </Paper>
                              <ContactDrawer
                                    open={drawerOpen}
                                    handleDrawerClose={handleDrawerClose}
                                    contact={contact}
                                    loading={loading}
                              />
                        </div>
                  </Box>
            </Modal>
      );
}

export default ViewTicketModal;