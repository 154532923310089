import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
      Button,
      TableBody,
      TableRow,
      TableCell,
      IconButton,
      Table,
      TableHead, 
      Paper,
} from "@material-ui/core";

import {
      SignalCellularConnectedNoInternet0Bar,
      SignalCellular4Bar,
      DeleteOutline,
} from "@material-ui/icons";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import ApiWhatsAppModal from "../../components/ApiWhatsAppModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { format, parseISO } from "date-fns";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
      mainPaper: {
            flex: 1,
            padding: theme.spacing(1),
            overflowY: "scroll",
            ...theme.scrollbarStyles,
      },
      customTableCell: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
      },
      tooltip: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
            maxWidth: 450,
      },
      tooltipPopper: {
            textAlign: "center",
      },
      buttonProgress: {
            color: green[500],
      },
}));

const Connections = () => { 
      const classes = useStyles();
      const [apiWhatsAppModalOpen, setApiWhatsAppModalOpen] = useState(false);

      const [dataApi, setDataApi] = useState([]);
      const [confirmModalOpen, setConfirmModalOpen] = useState(false);
      const confirmationModalInitialState = {
            action: "",
            title: "",
            message: "",
            whatsAppId: "",
            open: false,
      };
      const [confirmModalInfo, setConfirmModalInfo] = useState(
            confirmationModalInitialState
      );

      const handleCloseWhatsAppModal = () => {
            setApiWhatsAppModalOpen(false)
            getDataApi();
      }
      const handleOpenWhatsAppModal = () => {
            setApiWhatsAppModalOpen(true)
      }

      const getDataApi = async () => {
            let { data } = await api.get("/whatsapp/apiclients");
            setDataApi(data);
      }

      const handleOpenConfirmationModal = (action, IdApi) => {
            if (action === "delete") {
                  setConfirmModalInfo({
                        action: action,
                        title: i18n.t("apimodule.confirmationModal.deleteTitle"),
                        message: i18n.t("apimodule.confirmationModal.deleteMessage"),
                        IdApi: IdApi,
                  });
                  setConfirmModalOpen(true);
            }
      };

      const handleSubmitConfirmationModal = async () => {
            if (confirmModalInfo.action === "delete") {
                  try {
                        await api.delete(`/whatsapp/deleteapi/${confirmModalInfo.IdApi}`);
                        toast.success(i18n.t("connections.toasts.deleted"));
                  } catch (err) {
                        toastError(err);
                  }
            }

            setConfirmModalInfo(confirmationModalInitialState);
            getDataApi();
      };


      useEffect(() => {
            (async () => {
                  try {
                        getDataApi();
                  } catch (err) {
                        toastError(err);
                  }
            })();
      }, []);
      return (
            <MainContainer>
                  <ConfirmationModal
                        title={confirmModalInfo.title}
                        open={confirmModalOpen}
                        onClose={setConfirmModalOpen}
                        onConfirm={handleSubmitConfirmationModal}
                  >
                        {confirmModalInfo.message}
                  </ConfirmationModal>
                  <ApiWhatsAppModal
                        open={apiWhatsAppModalOpen}
                        onClose={handleCloseWhatsAppModal}
                        apiId={dataApi?.id}
                  />
                  <MainHeader>
                        <Title>{i18n.t("apimodule.title")}</Title>
                        <MainHeaderButtonsWrapper>
                              <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenWhatsAppModal}
                              >
                                    {i18n.t("connections.buttons.add")}
                              </Button>
                        </MainHeaderButtonsWrapper>
                  </MainHeader>
                  <Paper className={classes.mainPaper} variant="outlined">
                        <Table size="small">
                              <TableHead>
                                    <TableRow>
                                          <TableCell align="center">
                                                <div style={{ width: '100px' }}>
                                                      {i18n.t("apimodule.table.apiName")}
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                <div style={{ width: '100px' }}>
                                                      {i18n.t("apimodule.table.connectionName")}
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                <div style={{ width: '100px' }}>
                                                      {i18n.t("apimodule.table.createAt")}
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                <div style={{ width: '80px' }}>
                                                      {i18n.t("apimodule.table.state")}
                                                </div>
                                          </TableCell>
                                          <TableCell align="center">
                                                {i18n.t("apimodule.table.token")}
                                          </TableCell>
                                          <TableCell align="center">
                                                {i18n.t("apimodule.table.actions")}
                                          </TableCell>
                                    </TableRow>
                              </TableHead>
                              <TableBody>
                                    
                                          <>
                                                {dataApi?.length > 0 &&
                                                      dataApi.map(data => (
                                                            <TableRow key={data.id}>
                                                                  <TableCell align="center">
                                                                        <div style={{ width: '100px' }}>
                                                                              {data.name}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <div style={{ width: '100px' }}>
                                                                              {data.Whatsapp.name}
                                                                        </div>
                                                                  </TableCell>

                                                                  <TableCell align="center">
                                                                        <div style={{ width: '100px' }}>
                                                                              {format(parseISO(data.createdAt), "dd/MM/yy HH:mm")}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <div style={{ width: '80px' }}>
                                                                              {data.status ?
                                                                                    <SignalCellular4Bar style={{ color: green[500] }} />
                                                                                    :
                                                                                    <SignalCellularConnectedNoInternet0Bar color="secondary" />
                                                                              }
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <div style={{ maxWidth: '800px', textAlign: "justify", overflow: "hidden" }}>
                                                                              {data.token}
                                                                        </div>
                                                                  </TableCell>
                                                                  <TableCell align="center">
                                                                        <IconButton
                                                                              size="small"
                                                                              onClick={e => {
                                                                                    handleOpenConfirmationModal("delete", data.id)
                                                                              }}
                                                                        >
                                                                              <DeleteOutline />
                                                                        </IconButton>
                                                                  </TableCell>
                                                            </TableRow>
                                                      ))}
                                          </>
                                   
                              </TableBody>
                        </Table>
                  </Paper>
            </MainContainer>
      );
};

export default Connections;
