import React, { useState, useEffect } from "react";

import { Formik, Form, Field } from "formik";
// import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import SessionsSelect from "../SessionsSelect";

import BotAddStepModal from "../BotAddStepModal";
import BotAddMenuModal from "../BotAddMenuModal";

import { i18n } from "../../translate/i18n";

// import api from "../../services/api";
// import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "40%",
		left: "40%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	selectField: {
		height: 40,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "35%"
	}
}));

const BotMenuModal = ({ open, onClose, botId }) => {
	const classes = useStyles();

	const [botData, setBotData] = useState({
		"id": "",
		"whatsappId": "",
	})

	const [botMenuData, setBotMenuData] = useState({
		"initial_path": "",
		"error_message": "",
		"goodbye_message": "",
		"menu": {},
		"steps": {},
	});

	const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
	const [stepsModalIsOpen, setStepsModalIsOpen] = useState(false);

	const [selectedMenuToEdit, setSelectedMenuToEdit] = useState({});
	const [selectedStepToEdit, setSelectedStepToEdit] = useState({});


	useEffect(() => {
		(async () => {
			try {
				if (!botId) {
					return;
				}
				const { data } = await api.get(`/botMenu/${botId}`);
				setBotMenuData({
					"initial_path": data?.menu?.initial_path || "",
					"error_message": data?.menu?.error_message || "",
					"goodbye_message": data?.menu?.goodbye_message || "",
					"menu": data?.menu?.menu || {},
					"steps": data?.menu?.steps || {},
				});
				setBotData({ "id": data.botId, "whatsappId": data.whatsappId });

			} catch (err) {
				console.log(err);
			}
		})();
	}, [botId]);


	const handleMenuModal = () => {
		setMenuModalIsOpen(!menuModalIsOpen);
	};

	const handleStepsModal = () => {
		setStepsModalIsOpen(!stepsModalIsOpen);
	};

	const handleClose = () => {
		setBotData({});
		setBotMenuData({});
		onClose();
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setBotMenuData((prevData) => ({ ...prevData, [name]: value }));
	};


	const onSubmitAddMenuModal = (data) => {
		setBotMenuData((prevData) => (
			{ ...prevData, "menu": { ...prevData.menu, [data.menu_name]: data } }
		));
		setSelectedMenuToEdit(null);
	}

	const onSubmitAddStepModal = (data) => {
		setBotMenuData((prevData) => (
			{ ...prevData, "steps": { ...prevData.steps, [data.step_name]: data } }
		));
		setSelectedStepToEdit(null);
	}

	const onRemoveMenu = (menu_name) => {
		delete botMenuData.menu[menu_name];
		setSelectedMenuToEdit(null);
		handleMenuModal()
	}
	const onRemoveStep = (step_name) => {
		delete botMenuData.steps[step_name];
		setSelectedStepToEdit(null);
		handleStepsModal()
	}

	const handleSubmitForm = async () => {
		if(botData.whatsappId.length === 0 ){
			alert("Seleccione un conexión");
			return;
		}
		
		const data = {
			botId: botId,
			whatsappId: botData.whatsappId,
			menu: botMenuData,
			status: 1,
		}

		if (botId) {
			await api.put(`/botMenu/${botId}`, data);
			return;
		}
	}

	return (
		<div>
			<Dialog open={open} onClose={handleClose} scroll="paper" fullWidth maxWidth="md">
				<DialogTitle>
					{`${i18n.t("bots.botMenuModal.edit")}`}
				</DialogTitle>

				<Formik
					enableReinitialize={true}
					initialValues={botMenuData}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							// handleSaveQueue(values);
							actions.setSubmitting(false);
							handleSubmitForm();
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<SessionsSelect
									selectedSessionIds={botData?.whatsappId || ``}
									onChange={(selected) => {
										setBotData({ ...botData, "whatsappId": selected });
									}} />
								<Field
									as={TextField}
									label={i18n.t("bots.botMenuModal.fields.initial_path")}
									autoFocus
									name="initial_path"
									error={touched.initial_path && Boolean(errors.initial_path)}
									helperText={touched.initial_path && errors.initial_path}
									variant="outlined"
									margin="dense"
									className={classes.textField}
									onChange={handleInputChange}
									value={botMenuData?.initial_path || ""}
								/>

								<Field
									as={TextField}
									label={i18n.t("bots.botMenuModal.fields.error_message")}
									name="error_message"
									error={touched.error_message && Boolean(errors.error_message)}
									helperText={touched.error_message && errors.error_message}
									variant="outlined"
									margin="dense"
									className={classes.textField}
									onChange={handleInputChange}
									value={botMenuData?.error_message || ""}
								/>

								<Field
									as={TextField}
									label={i18n.t("bots.botMenuModal.fields.goodbye_message")}
									name="goodbye_message"
									error={touched.goodbye_message && Boolean(errors.goodbye_message)}
									helperText={touched.goodbye_message && errors.goodbye_message}
									variant="outlined"
									margin="dense"
									className={classes.textField}
									onChange={handleInputChange}
									value={botMenuData?.goodbye_message || ""}
								/>

								<hr />
								<div id="btn-menus">
									<div>
										<h3>{i18n.t("bots.botMenuModal.menu_list")}</h3>
										{Object.keys(botMenuData?.menu || []).map((e) => {
											const menu = botMenuData.menu[e];
											return (<Button
												key={e}
												color="primary"
												variant="outlined"
												onClick={() => {
													setSelectedMenuToEdit(menu);
													handleMenuModal();
												}}
											>
												{e}
											</Button>)
										})}
									</div>

									<div>
										<h3>{i18n.t("bots.botMenuModal.step_list")}</h3>
										{Object.keys(botMenuData?.steps || []).map((e) => {
											const step = botMenuData.steps[e];
											return (
												<Button
													key={e}
													color="primary"
													variant="outlined"
													onClick={() => {
														setSelectedStepToEdit(step);
														handleStepsModal();
													}}
												>
													{e}
												</Button>)
										})}
									</div>
								</div>


							</DialogContent>
							<DialogActions>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => {
										setSelectedMenuToEdit(null);
										handleMenuModal();
									}}
								>
									{i18n.t("bots.botMenuModal.add_menu")}
								</Button>
								<Button
									color="primary"
									variant="outlined"
									onClick={() => {
										setSelectedStepToEdit(null);
										handleStepsModal();
									}}
								>
									{i18n.t("bots.botMenuModal.add_step")}
								</Button>
								<Button
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
									onClick={handleClose}
								>
									{i18n.t("bots.botMenuModal.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{botId
										? i18n.t("bots.botMenuModal.edit")
										: i18n.t("bots.botMenuModal.add")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>


			</Dialog>
			< BotAddMenuModal open={menuModalIsOpen} onClose={handleMenuModal} onRemove={onRemoveMenu} onSubmit={onSubmitAddMenuModal} initialData={selectedMenuToEdit} />
			< BotAddStepModal open={stepsModalIsOpen} onClose={handleStepsModal} onRemove={onRemoveStep} onSubmit={onSubmitAddStepModal} initialData={selectedStepToEdit} />
		</div>
	);
};

export default BotMenuModal;
