import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "40%",
		left: "40%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	selectField: {
		height: 40,
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "35%"
	}
}));



const ApiAuthentificationMethod = ({ method, initialData, onChange }) => {
	const classes = useStyles();

	const [formData, setFormData] = useState({
		user: "",
		password: "",
		token: "",
		apikey: "",
	});

	useEffect(() => {
		setFormData({
			user: initialData?.user || "",
			password: initialData?.password || "",
			token: initialData?.token || "",
			apikey: initialData?.apikey || "",
		});
	}, [initialData]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
		const data = { ...formData, [name]: value };
		const parentData = {
			target: {
				name: "auth",
				value: data,
			}
		}
		onChange(parentData);
	};

	if (method === "basic") {
		return <>
			<TextField
				label="Usuario"
				name="user"
				variant="outlined"
				margin="dense"
				className={classes.textField}
				style={{ width: "47%" }}
				onChange={handleInputChange}
				value={formData.user}
			/>
			<TextField
				label="Contraseña"
				name="password"
				variant="outlined"
				margin="dense"
				className={classes.textField}
				style={{ width: "48%" }}
				onChange={handleInputChange}
				value={formData.password}
			/>
		</>
	}

	if (method === "token") {
		return <TextField
			label="Token"
			name="token"
			variant="outlined"
			margin="dense"
			className={classes.textField}
			style={{ width: "96%" }}
			onChange={handleInputChange}
			value={formData.token}
		/>
	}

	if (method === "apikey") {
		return <TextField
			label="Api Key"
			name="apikey"
			variant="outlined"
			margin="dense"
			className={classes.textField}
			style={{ width: "96%" }}
			onChange={handleInputChange}
			value={formData.apikey}
		/>
	}

	return <></>
}

export default ApiAuthentificationMethod