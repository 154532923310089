import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
// import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { green } from "@material-ui/core/colors";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import QueueSelect from "../QueueSelect";
import StepApiType from "../BotStepApiType";
import { makeStyles } from "@material-ui/core/styles";

import { DeleteOutline } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "40%",
    left: "40%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  selectField: {
    height: 40,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "35%"
  }
}));

const BotAddStepModal = ({ open, onClose, onSubmit, onRemove, initialData }) => {
  const classes = useStyles();

  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    step_name: "",
    goto_code: "",
    goto_target: "",
    label: "",
    type: "text",
    attemps: "",
    reserved_word: "",
    validator: "",
    api: {},
    assign_to: "",
    static_response: ""
  });

  useEffect(() => {
    setFormData({
      step_name: initialData?.step_name || "",
      label: initialData?.label || "",
      type: initialData?.type || "text",
      attemps: (initialData?.attemps || []).join(","),
      api: initialData?.api || {},
      goto_code: initialData?.goto?.code || "",
      goto_target: initialData?.goto?.target || "menu",
      reserved_word: initialData?.reserved_word || "",
      validator: initialData?.validator || "",
      assign_to: initialData?.assign_to || "",
      static_response: initialData?.static_response || "",
    })
  }, [initialData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // console.log(formData);
  };

  const handleSubmitForm = () => {
    let data = formData;
    data["goto"] = {
      "target": data.goto_target,
      "code": data.goto_code
    }
    data["attemps"] = data["attemps"].split(",");

    delete data.goto_code;
    delete data.goto_target;

    onSubmit(data);
  }

  const clearForm = () => {
    setFormData({
      step_name: "",
      goto_code: "",
      goto_target: "",
      label: "",
      type: "text",
      attemps: "",
      reserved_word: "",
      validator: "",
      api: {},
      static_response: "",
      assign_to: ""
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {i18n.t("bots.botAddStepModal.title")}
        {initialData ?
          <Button onClick={() => { onRemove(initialData.step_name) }}>
            <DeleteOutline />
          </Button> :
          <></>}
      </DialogTitle>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
            handleSubmitForm();
            clearForm();
            onClose();
          }, 400);
        }}
      >
        {({ touched, errors }) => {
          return (
            <Form>
              <DialogContent dividers>
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.step_name")}
                  name="step_name"
                  error={touched.step_name && Boolean(errors.step_name)}
                  helperText={touched.step_name && errors.step_name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  onChange={handleInputChange}
                  value={formData.step_name}
                  style={{ width: "45%" }}
                />
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.label")}
                  name="label"
                  error={touched.label && Boolean(errors.label)}
                  helperText={touched.label && errors.label}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  onChange={handleInputChange}
                  value={formData.label}
                  style={{ width: "40%" }}
                />
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.validator")}
                  name="validator"
                  error={touched.validator && Boolean(errors.validator)}
                  helperText={touched.validator && errors.validator}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  onChange={handleInputChange}
                  value={formData.validator}
                  style={{ width: "45%" }}
                />
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.reserved_word")}
                  name="reserved_word"
                  error={touched.reserved_word && Boolean(errors.reserved_word)}
                  helperText={touched.reserved_word && errors.reserved_word}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  onChange={handleInputChange}
                  value={formData.reserved_word}
                  style={{ width: "40%" }}
                />
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.attemps")}
                  name="attemps"
                  error={touched.attemps && Boolean(errors.attemps)}
                  helperText={touched.attemps && errors.attemps}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                  style={{ width: "86%" }}
                  onChange={handleInputChange}
                  value={formData.attemps}
                  multiline={true}
                  maxRows={2}
                />
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.static_response")}
                  name="static_response"
                  error={touched.static_response && Boolean(errors.static_response)}
                  helperText={touched.static_response && errors.static_response}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  onChange={handleInputChange}
                  value={formData.static_response}
                  fullWidth
                  multiline
                />
                <QueueSelect
                  selectedQueueIds={[formData?.assign_to]}
                  onChange={values => setFormData({ ...formData, "assign_to": values, "goto_target": "finish", "goto_code": "" })}
                  multiple={false}
                />
                <Select
                  labelId="label_select_type"
                  id="select_type"
                  name="type"
                  label={i18n.t("bots.botAddStepModal.fields.type")}
                  value={formData.type}
                  onChange={handleInputChange}
                  fullWidth
                  className={classes.selectField}
                  variant="outlined"
                >
                  <MenuItem value="text" selected={true}>{i18n.t("bots.botAddStepModal.text")}</MenuItem>
                  <MenuItem value="api">{i18n.t("bots.botAddStepModal.api")}</MenuItem>
                </Select>

                {formData.type === "api" ? <StepApiType onChange={handleInputChange} initialData={formData.api} /> : <></>}
                <h2>Ir a</h2>
                <Select
                  labelId="select-label"
                  id="simple-select"
                  name="goto_target"
                  label={i18n.t("bots.botAddStepModal.fields.type")}
                  value={formData.goto_target}
                  onChange={handleInputChange}
                  fullWidth
                  className={classes.selectField}
                  variant="outlined"
                >
                  <MenuItem value="menu" selected={true}>{i18n.t("bots.botAddStepModal.select_options.menu")}</MenuItem>
                  <MenuItem value="steps">{i18n.t("bots.botAddStepModal.select_options.step")}</MenuItem>
                  <MenuItem value="finish">{i18n.t("bots.botAddStepModal.select_options.finish")}</MenuItem>
                </Select>
                <TextField
                  label={i18n.t("bots.botAddStepModal.fields.goto_code")}
                  name="goto_code"
                  error={touched.goto_code && Boolean(errors.goto_code)}
                  helperText={touched.goto_code && errors.goto_code}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  style={{ width: "60%" }}
                  onChange={handleInputChange}
                  value={formData.goto_code}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  onClick={onClose}
                  color="secondary"
                  variant="outlined"
                >
                  {i18n.t("bots.botAddStepModal.add_step")}
                </Button>
                <Button
                  onClick={onClose}
                  color="secondary"
                  variant="outlined"
                >
                  {i18n.t("bots.botAddStepModal.cancel")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default BotAddStepModal;